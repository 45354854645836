/*---------------------------- */
/* Setup navigation fixed menu */
/*---------------------------- */
let windowWidth = window.innerWidth;

function setHeaderHeight() {
  document.documentElement.removeAttribute("style");
  var headerHeight = document.querySelector("header").scrollHeight;
  document.documentElement.style.setProperty(
    "--header-height",
    `${headerHeight}px`
  );
}

window.addEventListener("resize", function () {
  // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
  if (window.innerWidth != windowWidth) {
    // Update the window width for next time
    windowWidth = window.innerWidth;
    // Do stuff here
    setHeaderHeight();
  }
  // Otherwise do nothing
});

document.addEventListener("DOMContentLoaded", function () {
  setHeaderHeight();
});

/*---------------------------- */
/* Setup Intersection Observer */
/*---------------------------- */

const header = document.querySelector(".sectionnav");
const sectionOne = document.querySelector(".sectionnav");

const faders = document.querySelectorAll(".fade-in");
const sliders = document.querySelectorAll(".slide-in");
// const imageScafi = document.getElementById("imagen-scafi-local-host");

const sectionOneOptions = {
  rootMargin: "-200px 0px 0px 0px",
};

const sectionOneObserver = new IntersectionObserver(function (
  entries,
  sectionOneObserver
) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      header.classList.add("nav-scrolled");
    } else {
      header.classList.remove("nav-scrolled");
    }
  });
},
sectionOneOptions);

// sectionOneObserver.observe(sectionOne);

const appearOptions = {
  threshold: 0,
  rootMargin: "0px 0px -250px 0px",
};

const appearOnScroll = new IntersectionObserver(function (
  entries,
  appearOnScroll
) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      entry.target.classList.remove("appear");
      return;
    } else {
      entry.target.classList.add("appear");
      // appearOnScroll.unobserve(entry.target);
    }
  });
},
appearOptions);

faders.forEach((fader) => {
  appearOnScroll.observe(fader);
});

sliders.forEach((slider) => {
  appearOnScroll.observe(slider);
});

// appearOnScroll.observe(imageScafi);
// observer.observe(sectionOne);

/*--------------------------------------- */
/* Setup show-hide fixed navigation menu  */
/*--------------------------------------- */

var lastScrollTop = 0;
navigationMenu = document.querySelector(".sectionnav");

window.addEventListener("scroll", () => {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > lastScrollTop) {
    navigationMenu.style.top = "-70px";
  } else {
    navigationMenu.style.top = "0";
  }
  lastScrollTop = scrollTop;
});

/*-------------------------------------------- */
/* Setup Responsive Drop Down Navigation Menu  */
/*-------------------------------------------- */

let navContainer = document.querySelector(".sectionnav__container");
let brand = document.querySelector(".sectionnav__brand");

// sidebar open close js code
let navigationBar = document.querySelector(".sectionnav__navigation");
let linkNavigationBar = document.querySelectorAll(".sectionnav__navigation-level1--link");
let menuHamburger = document.querySelector(".sectionnav__container .hamburger");
let menuHamburgerWidth = window.innerWidth;

menuHamburger.addEventListener("click", () => {
  if (menuHamburger.classList.contains("open")) {
    menuHamburger.classList.remove("open");
    navigationBar.style.right = "-100%";
    navigationBar.classList.remove("show1");
    navigationBar.classList.remove("show2");
    navigationBar.classList.remove("show3");
  } else {
    menuHamburger.classList.add("open");
    navigationBar.style.right = "0%";
  }
});

window.addEventListener("resize", function () {
  // Check window width has actually changed a resize event on scroll
  if (window.innerWidth != menuHamburgerWidth) {
    // Update the window width for next time
    menuHamburgerWidth = window.innerWidth;
    // Do stuff here
    menuHamburger.classList.remove("open");
    navigationBar.style.right = "-100%";
    navigationBar.classList.remove("show1");
    navigationBar.classList.remove("show2");
    navigationBar.classList.remove("show3");
  }
  // Otherwise do nothing
});

linkNavigationBar.forEach((menuLinks) => {
  menuLinks.addEventListener("click", () => {
    menuHamburger.classList.remove("open");
    navigationBar.style.right = "-100%";
    navigationBar.classList.remove("show1");
    navigationBar.classList.remove("show2");
    navigationBar.classList.remove("show3");
  })
});

/*---------------------------- */
/* Setup color picker          */
/*---------------------------- */

const colorThemes = document.querySelectorAll('[name="theme"]');

// store theme
const storeTheme = function (theme) {
  localStorage.setItem("theme", theme);
};

// set theme when visitor returns
const setTheme = function () {
  const activeTheme = localStorage.getItem("theme");
  colorThemes.forEach((themeOption) => {
    if (themeOption.id === activeTheme) {
      themeOption.checked = true;
    }
  });
  // fallback for no :has() support
  document.documentElement.className = activeTheme;
};

colorThemes.forEach((themeOption) => {
  themeOption.addEventListener("click", () => {
    storeTheme(themeOption.id);
    // fallback for no :has() support
    document.documentElement.className = themeOption.id;
  });
});

document.onload = setTheme();

/*------------------------------- */
/* Change second part a text      */
/*------------------------------- */

const text = document.querySelector(".changetext__span--second-text");

const textLoad = () => {
  setTimeout(() => {
    text.textContent = "Bueno";
  }, 0);
  setTimeout(() => {
    text.textContent = "Bonito";
  }, 4000);
  setTimeout(() => {
    text.textContent = "Barato";
  }, 8000);
  setTimeout(() => {
    text.textContent = "Increible";
  }, 12000);
};

textLoad();
setInterval(textLoad, 16000);

/*---------------------------- */
/* Dark Mode Theme             */
/*---------------------------- */

// check for saved 'darkMode' in localStorage
let darkMode = localStorage.getItem("darkMode");

const darkModeToggle = document.querySelector("#dark-mode-toggle");

const enableDarkMode = () => {
  // 1. Add the class to the body
  document.body.classList.add("darkmode");
  // 2. Update darkMode in localStorage
  localStorage.setItem("darkMode", "enabled");
};

const disableDarkMode = () => {
  // 1. Remove the class from the body
  document.body.classList.remove("darkmode");
  // 2. Update darkMode in localStorage
  localStorage.setItem("darkMode", null);
};

// If the user already visited and enabled darkMode
// start things off with it on
if (darkMode === "enabled") {
  enableDarkMode();
}

// When someone clicks the button
darkModeToggle.addEventListener("click", () => {
  // get their darkMode setting
  darkMode = localStorage.getItem("darkMode");

  // if it not current enabled, enable it
  if (darkMode !== "enabled") {
    enableDarkMode();
    // if it has been enabled, turn it off
  } else {
    disableDarkMode();
  }
});